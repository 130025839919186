@import "../../utilities/variables.scss";

.MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.ankieta-cont {
  width: 60%;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  // display: flex;
  // justify-content: space-around;
  // align-items: center
  @media screen and (max-width: 992px) {
    width: 100%;
  }


  .dalej-powrot {
    width: 100%;
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 992px) {
    
    }
  }

  .arrow-cont {
    opacity: 1;
    transition: 0.2s ease-out;

    cursor: pointer;
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    &:hover {
      transition: 0.2s;
      .arrow-para {
        color: $blue-color;
        transition: 0.2s;
        i {
          border-color: $blue-color;
          transition: 0.2s;
        }
      }
    }
    .arrow-para {
      font-weight: 700;

      transition: 0.2s ease-out;

      .arrow {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }

      .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        margin-right: 0.5rem;
      }
      .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-left: 0.5rem;
      }
    }
  }



  .survey-cont {
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // justify-content: center;
    // margin-top: 3rem;
    width: 60%;
    margin: 3rem auto;
    @media screen and (max-width: 1500px) and (min-width: 993px) {
      width: 80% !important;
    }
    @media screen and (max-width: 992px) {
      width: 95%;
      margin: 1.5rem auto;
    }
    .pytanie {
      text-align: left;
    }
    .radio-cont {
      // display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      margin-top: 1rem;
      .radio {
        display: flex;
        // flex-direction: column;
        justify-content: flex-start;
        // align-items: center;
        // width: 8rem;
        label {
          font-size: 0.8rem;
        }
      }
      .MuiFormGroup-root {
        flex-direction: row;
      }
      .MuiFormControlLabel-root {
        flex: 2 1 32.333333%;
        @media screen and (max-width: 1550px) {
          flex: 1 1 45%;
        }
      }
    }

    hr {
      width: 100%;
      background-color: $blue-color;
      opacity: 0.2;
      height: 2px;
      border: none;
      // margin-top: 3rem;
      margin: 1.5rem 0;
      @media screen and (max-width: 1550px) {
        width: 100%;
      }
    }
    .btn-template {
      width: 10rem;
      transition: 0.2s ease-out;
      &:hover {
        transition: 0.2s;
        color: $red-color;
        border-color: $red-color;
      }
    }

    .acordeaon-heading {
      font-size: 1.2rem;
      font-weight: 700;
    }

    .grupa-name {
      text-align: left;
      font-size: 1.1rem;
      font-weight: 700;
      @media screen and (max-width: 992px) {
     font-size: 0.9rem !important;
      }
      @media screen and (max-width: 500px) {
      width: 100%;
      }
    }
    .grupa-sum {
      text-align: right;
      width: 10rem;
      font-size: 1.1rem;
      font-weight: 700;
      @media screen and (max-width: 992px) {
        width: 100%;
        font-size: 0.9rem;
      }
      @media screen and (max-width: 500px) {
      text-align: left;
      margin-top: 0.5rem;
      }
    }
.arrow-zwin {
  font-weight: 700;
  cursor: pointer;
}
    .arrow {
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin-right: 1rem;
      margin-bottom: -2px;
    }

    .left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin-right: 0.5rem;
    }
    .up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }


  }
}


.ankieta {
  .btn-template {
    transition: 0.2 ease-out;
    &:hover {
      border-color: $red-color;
      color: $red-color;
      transition: 0.2s;
    }
  }
  @media screen and (max-width: 992px) {
    h2 {
      padding: 0.2rem 1.2rem;
    }
  }

}

.survey-cont {
  margin-top: 2rem;
  @media screen and (max-width: 992px) {
    h2 {
      padding: 0.2rem 1.2rem;
    }
  }
  .wynik {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 2rem;
    padding: 1.5rem;
    box-shadow:   0 1px 2px rgba(0,0,0,0.07), 
    0 2px 4px rgba(0,0,0,0.07), 
    0 4px 8px rgba(0,0,0,0.07);
    border: 0.1px solid transparent;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    span {
      font-size: 1rem;
      font-weight: 700;
    }
    .name {
    margin-bottom: 1rem;
    }
    }

}




.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $red-color !important;
}

.MuiDataGrid-root .MuiDataGrid-cellLeft.MuiDataGrid-cellWithRenderer,
.MuiDataGrid-root .MuiDataGrid-cellLeft.MuiDataGrid-cellEditing {
  justify-content: flex-start !important;
}

.MuiDataGrid-root .MuiDataGrid-cellLeft {
  width: 33% !important;
  text-align: left !important;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator {
  right: -14px !important;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected 
    /* background-color: rgba(63, 81, 181, 0.08); */ {
  background-color: transparent !important /* rgba(63, 81, 181, 0.08); */;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
  background-color: rgba(128, 128, 128, 0.1) !important;
}

.MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

.searchBar {
  box-shadow: none !important;
  margin-bottom: 1rem;
  border: 1px solid rgba(224, 224, 224, 1) !important;
}

.MuiAccordionDetails-root {
  display: flex;
  flex-direction: column;
}

.MuiTypography-body1 {
  font-family: "Rubik", sans-serif;
  @media screen and (max-width: 992px) {
  font-size: 0.8rem !important;
  text-align: left;
    }
}
