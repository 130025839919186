@import "../../utilities/variables.scss";

.ankieta-cont {
  width: 60%;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  // display: flex;
  // justify-content: space-around;
  // align-items: center
  @media screen and (max-width: 1500px) and (min-width: 993px) {
    width: 85% !important;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
  }

  .ankieta-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 992px) {
      width: 95%;
      margin: 0 auto;
    }
    h3 {
      flex-shrink: 0;
    }
    button + button {
      margin-left: 15px;
    }
  }
  .ankieta-btn {
    background-color: transparent;
    transition: 0.2s ease-out;
    cursor: pointer;
    &:hover {
      border-color: $blue-color;
      color: $blue-color;
      transition: 0.2s;
    }
    &--active {
      background: #000;
      color: #fff;
      &:hover {
        border-color: $blue-color;
        color: $blue-color;
      }
    }
  }

  .button-table {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 12px;
    padding: 1rem;
    min-width: 6rem;
    background-color: transparent;
    border: 2px solid black;
    border-radius: 10px;
    transition: 0.2s ease-out;
    @media screen and (max-width: 600px) {
      min-width: 3rem;
    }
    &:hover {
      border-color: $yellow-color;
      color: $yellow-color;
      transition: 0.2s;
    }
  }

  .dalej-powrot {
    width: 100%;
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }
  // .arrow-cont-dalej {
  // }

  // .powrot {
  // }
  // .dalej {
  // }

  .arrow-cont {
    opacity: 1;
    transition: 0.2s ease-out;

    cursor: pointer;
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    &:hover {
      transition: 0.2s;
      .arrow-para {
        color: $blue-color;
        transition: 0.2s;
        i {
          border-color: $blue-color;
          transition: 0.2s;
        }
      }
    }
    .arrow-para {
      font-weight: 700;

      transition: 0.2s ease-out;

      .arrow {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }

      .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        margin-right: 0.5rem;
      }
      .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-left: 0.5rem;
      }
    }
  }

  .numer-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 4rem;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    .pesel {
      margin-right: 2rem;
      @media screen and (max-width: 600px) {
        justify-content: center;
        margin-right: 0rem;
      }
    }
    .pass {
      margin-left: 2rem;
      @media screen and (max-width: 600px) {
        justify-content: center;
        margin-left: 0rem;
      }
    }

    .inputPe {
      outline: none;
      border: 2px solid black;
      border-radius: 10px;
      padding: 0.3rem;
      text-align: center;

      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .lub {
      @media screen and (max-width: 600px) {
        padding: 1.5rem 0.5rem 1.5rem;
      }
    }
  }

  .button-cont {
    margin-top: 4rem;
    @media screen and (max-width: 600px) {
      margin-top: 3rem;
    }
    .btn-template {
      margin: 0 3rem;
      width: 6rem;
      height: 3rem;
      transition: 0.2s ease-out;
      opacity: 1;
      &.active {
        border-color: $red-color;
        color: $red-color;
        &:hover {
          opacity: 1;
        }
      }
      @media screen and (max-width: 600px) {
        margin: 1rem 1rem;
      }
      &:hover {
        border-color: $red-color;
        color: $red-color;
        transition: 0.2s;
        opacity: 0.6;
      }
    }
  }

  .survey-cont {
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // justify-content: center;
    // margin-top: 3rem;
    width: 60%;
    margin: 3rem auto;
    .pytanie {
      text-align: left;
    }
    .radio-cont {
      // display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      margin-top: 1rem;
      .radio {
        display: flex;
        // flex-direction: column;
        justify-content: flex-start;
        // align-items: center;
        // width: 8rem;
        label {
          font-size: 0.8rem;
        }
      }
    }

    hr {
      width: 100%;
      background-color: $blue-color;
      opacity: 0.2;
      height: 2px;
      border: none;
      // margin-top: 3rem;
      margin: 2rem 0;
      @media screen and (max-width: 1550px) {
        width: 100%;
      }
    }
    .btn-template {
      width: 10rem;
      transition: 0.2s ease-out;
      &:hover {
        transition: 0.2s;
        color: $red-color;
        border-color: $red-color;
      }
    }

    .acordeaon-heading {
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
}

// .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
//   outline: none !important;
// }

// .MuiRadio-colorSecondary.Mui-checked {
// color: $red-color !important;
// }

//  .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover  {
// background-color: rgba($red-color, 0.2) !important;
// }

// .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
//   background-color: rgba($red-color, 0.2) !important
// }

// .MuiDataGrid-root .MuiDataGrid-cellLeft.MuiDataGrid-cellWithRenderer, .MuiDataGrid-root .MuiDataGrid-cellLeft.MuiDataGrid-cellEditing {
//  border: 2px solid black;
// }

// .MuiDataGrid-root .MuiDataGrid-cellLeft.MuiDataGrid-cellWithRenderer,
// .MuiDataGrid-root .MuiDataGrid-cellLeft.MuiDataGrid-cellEditing {
//   justify-content: flex-start !important;
// }

// .MuiDataGrid-root .MuiDataGrid-cellLeft {
//   width: 33% !important;
// }

// .MuiDataGrid-root .MuiDataGrid-columnSeparator {
//   right: -14px !important;
// }

// .MuiDataGrid-root .MuiDataGrid-row.Mui-selected
//     /* background-color: rgba(63, 81, 181, 0.08); */ {
//   background-color: transparent !important /* rgba(63, 81, 181, 0.08); */;
// }

// .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
//   background-color: rgba(128, 128, 128, 0.1) !important;
// }

// .MuiDataGrid-selectedRowCount {
//   visibility: hidden !important;
// }

// .searchBar {
//     box-shadow: none !important;
//     margin-bottom: 1rem;
//     border: 1px solid rgba(224, 224, 224, 1) !important;

// }

// .MuiAccordionDetails-root {
//     display: flex;
//     flex-direction: column;

// }

// .MuiTypography-body1 {

//   font-family: 'Rubik', sans-serif;

// }
