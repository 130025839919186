@import "../../utilities/variables.scss";

.login {
  // margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  transform: translate(-50%, 0);
  top: 20%;
  left: 50%;
  .logo {
    width: 20rem;
    @media screen and (max-width: 600px) {
      width: 12rem;
    }
  }

  .formik-cont {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 20rem;
    @media screen and (max-width: 600px) {
      width: 12rem;
    }
    .inp {
  
      border-radius: 10px;
      outline: none;
      font-family: $font-base;
      // margin-top: 2rem;
      text-align: center;
      font-weight: 400;
    }
    .submit {
      cursor: pointer;
      width: 50%;
      margin: 0 auto;
      font-weight: bold;
    
      background-color: transparent;
      transition: 0.2s ease-out;
      @media screen and (max-width: 600px) {
        width: 70%;
      }
      // margin-top: 2rem;
      &:hover {
        border-color: $red-color;
        transition: 0.2s;
        color: $red-color;
      }
    }
    .error {
     padding: 0.5rem;
      min-height: 20px;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
      opacity: 0.8;
      color: $red-color;
    }
  }
}



.btn-template {
  outline: none;
  border: 2px solid black;
  border-radius: 10px;
  font-weight: 700;
  padding: 0.5rem 1rem;
}


.input-login {
  margin-bottom: 2rem !important;
}


.MuiFormLabel-root.Mui-focused {
  color: $blue-color !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
border-color: $blue-color !important;
}