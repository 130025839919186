@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  font-family: "Rubik", sans-serif;
}

input {
  font-family: "Rubik", sans-serif;
}

a {
  text-decoration: none;
}

.btn-template {
  outline: none;
  border: 2px solid black;
  border-radius: 10px;
  font-weight: 700;
  background-color: transparent;
  cursor: pointer;
}
