$base-color: #c6538c;
$border-color: #707070;
@import "./utilities/variables.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: white;
  display: flex;
  border-bottom: 1px solid rgba($border-color, 0.2);
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);

  padding: 1rem 5rem;
  flex-wrap: wrap;
  @media screen and (max-width: 600px) {
    padding: 1rem 1rem;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }
}

.App-body {
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
  .ankieta {
    margin-right: 5rem;
  }
  .ankieta,
  .ankieta2 {
    background-color: transparent;

    padding: 0.5rem 2rem;
    transition: 0.2s ease-out;
    cursor: pointer;
    &:hover {
      transition: 0.2s;
      border-color: $red-color;
      color: $red-color;
    }
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
