@import "../../utilities/variables.scss";


.log-outcont {
display: flex;
align-items: center;
p {
  font-size: 1rem;
  margin-right: 3rem;
  // padding: 1rem;
  @media screen and (max-width: 992px) {
    display: none;
  }

}

  .log-out {
    display: flex;
    align-self: center;
    .btn-out {
      outline: none;
    
      padding: 0.5rem 2rem;
      background-color: transparent;
      cursor: pointer;
      transition: 0.2s ease-out;
      font-weight: bold;
      @media screen and (max-width: 600px) {
        margin-top: 1rem;
      }
      &:hover {
        border-color: $red-color;
        color: $red-color;
        transition: 0.2s;
      }
    }
  }
}

.paragraph-header {
  font-size: 1rem;
  margin-right: 3rem;
  @media screen and (min-width: 992px) {
    display: none;
  }
  @media screen and (max-width: 600px) {
    margin-right: 0rem;
  }
}

